import { Locale }                    from '@lib-widgets/reactive/locale';
import { Environment, TargetMarket } from './environment.interfaces';

export const environment: Environment = {
  production: true,
  coreApiUrl: window.location.protocol + "//" + window.location.host + "/api/core/",
  isSsoAuth: true,
  defaultLanguage: Locale.ru,
  markets: [TargetMarket.KZ, TargetMarket.UZ]
};

